// Branding setup for Kyivstar

body {
    background: url("../../static/img/kyivstar_background.jpg") no-repeat top center / cover fixed;
}

#w-app-title {
    display: flex;
    align-items: center;
}

#client-logo {
    width: 131px;
    height: 31px;
    font-size: 0;
    background: url("../../static/img/kyivstar_logo.svg") no-repeat transparent top left / contain;
    margin: -3px 11px -1px 2px;
}
