@import "~/styles/_variables.scss";

.available-msisdn {
    &-msisdns {
        display: flex;
        flex-wrap: wrap;

        .custom-control {
            padding-right: $padding-size * 2;
            min-width: 141px;
        }
    }
}
