// Manual style override for react-dates inputs

.DateInput_input {
    display: block;
    width: 100%;
    height: $input-height;
    padding: $input-padding-y $input-padding-x;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    @include border-radius($input-border-radius, 0);
    border: $input-border-width solid $input-border-color;
    
    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    &::-ms-expand {
        background-color: transparent;
        border: 0;
      }
    
      &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $input-color;
      }

      @include form-control-focus($ignore-warning: true);
    
      &::placeholder {
        color: $input-placeholder-color;
        opacity: 1;
      }
    
      &:disabled,
      &[readonly] {
        background-color: $input-disabled-bg;
        opacity: 1;
      }
}
.DateInput_input__regular {
    @extend .form-control;
    @extend .is-invalid;
    border-color: theme-color(danger);
}

.SingleDatePickerInput__withBorder {
    border: 0;
}

.CalendarDay__selected {
    &, &:active, &:hover {
        background: theme-color(primary);
        border-color: theme-color(primary);
    }
}

.CalendarMonth_caption {
    padding-top: 18px;

    > div > div {
        margin: 0 1px;
    }

    select {
        @extend .custom-select;
        height: $input-height-sm;
        line-height: $input-line-height-sm;
        padding-top: $input-padding-y-sm;
        padding-bottom: $input-padding-y-sm;
        padding-left: $input-padding-x-sm;      
    }
}
