.customers {
    height: 110px;
    overflow: scroll;
    .result {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        grid-template-rows: 30px 30px 20px 1fr;
        align-items: center;
        margin: $step-size 0;
        .title {
            grid-column: 1 / 2;
        }
        .value {
            grid-column: 2 / 3;
        }
        .button {
            grid-column: 3 / 4;
            grid-row: 1 / 5;
        }
        .message {
            grid-column: 1 / 4;
        }
    }
}

@media (max-width: 576px) {
    .result {
        display: grid;
        grid-template-columns: minmax(70px, auto) 1fr;
        grid-template-rows: 30px 30px 10px 1fr;
        .title {
            grid-column: 1 / 2;
        }
        .value {
            grid-column: 2 / 3;
        }
        .button {
            grid-column:  1 / 3;
            grid-row: 4 / 4;
        }
    }
}
