@include media-breakpoint-down($weasel-container-mobile-breakpoint){

    // Stretch context bar to the edges
    .w-nav-context {
        margin: 0;
        border-radius: 0;
        background: #229fff !important;
    }

    // Hide logged in user info
    #w-app-actions {
        .w-action-item-label {
            display: none;
        }
    }

    // Hide logo
    #client-logo {
        display: none;
    }
}
