@import "~/styles/_variables.scss";

.navigation {
    display: flex;
    .btn {
        font-weight: 400;
        color: $primary;
        text-decoration: none;
    }
    @media (max-width: 576px) {
        width: 100%;
        flex-direction: column;
        .btn {
            color: #fff;
            background-color: $primary;
            border-color: $primary;
        }
        .btn:nth-of-type(2) {
            margin: 10px 0;
        }
    }
}