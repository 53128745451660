@import "~/styles/_variables.scss";

.Plan-Page {
    &-head {
        color: $title-color;
        display: grid;
        grid-template-columns: 70% 25% 5%;
        padding: 10px;
    }
    @media(max-width: 576px) {
        &-head {
            grid-template-columns: 50% 50%;
        }
    }

    &-content {
        padding-left: 5%;
    }
}
