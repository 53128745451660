@import "~/styles/_variables.scss";

.Available-Tariff-Plans {
    &-head {
        display: grid;
        grid-template-columns: 50% 25% 25%;
        row-gap: 1em;
        margin-bottom: .5rem;
        color: #9c9c9c;
        > div:nth-of-type(1) {
            padding-left: 10px;
        }
    }
    @media(max-width: 576px) {
        &-head {
            grid-template-columns: 33% 33% 33%;
        }
    }
}
