$step-size: 12px !default;
$size: 8px;
$padding-size: $size;
$margin-size: $size;
$text-hint-color: #bdbdbd;
$background-color: #fafafa;
$border-size: 1px;
$border-color: #bdbdbd;
$border-color-group: #e2e2e2;
$border-color-item-left:#229fff;;
$burger-menu-background: #01385f;
$burger-menu-active-tab-text-color: #F57632;
$burger-menu-active-tab-background: #012f4f;
$title-color: #9c9c9c;

// grid system size:
$sm: 540px;
$md: 720px;
$lg: 960px;
$xl: 1140px;