@import "~/styles/_variables.scss";

.ContactInfo {
    .block {
        @include make-row();
        .field {
            padding: $step-size $grid-gutter-width/2;
            @include make-col(6);
        }
    }
}