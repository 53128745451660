@import "~/styles/_variables.scss";

.table-header-cell {
    &:first-child {
        width: 60%;
    }
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-bottom-color: theme-color(primary) !important;
    width: 30%;

    .Plan-title {
        font-size: $h6-font-size;
    }
}

.table-cell {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.Plan-name {
    font-size: $h5-font-size;
}


.Light-color {
    color: theme-color(secondary) !important;
}
