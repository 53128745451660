@import "~/styles/_variables.scss";

.Spiner-iPos {
  &-container{
    height: 85vh;
    &-box {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 20rem;
    }
  }
}
