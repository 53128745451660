#w-app-search #dropdown-custom-1 {
    box-shadow: none;
    border: 0;
    border-radius: 0 2px 2px 0;
    padding: 6px 12px;
    background-color: $weasel-navbar-background-base;
    color: $weasel-navbar-text-color;

    &:hover, &:focus, &:active {
        outline: 0;
        background-color: $weasel-navbar-background-hover;
    }
}
//Burger Menu styles
#w-app-header .mobile-nav {
    display: none;
    flex: 0 0 100%;
    color: $weasel-navbar-text-color;
}
#w-app-header .mobile-nav h3 {
    margin-top: 1rem;
}
#w-app-header .mobile-nav ul {
    display: block;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -.5rem;
}
#w-app-header .mobile-nav li {
    flex: 0 0 50%;
    padding: 0 .5rem;
}
@media (max-width: 540px) {
    #w-app-header .mobile-nav li {
        flex-basis: 100%;
    }
}

#w-app-header .mobile-nav button {
    display: block;
    font-size: 1.125rem;
    border-top: 1px solid $weasel-navbar-background-base;
    padding: .8rem 1rem;
}

#w-app-header .mobile-nav a:link, #w-app-header .mobile-nav button:visited, #w-app-header .mobile-nav button:active {
    color: $weasel-navbar-background-active;
}

#w-app-header .mobile-nav button:hover, #w-app-header .mobile-nav li.active button:hover, #w-app-header .mobile-nav  button:hover .fa {
    color: $weasel-navbar-text-color;
    opacity: 1;
    text-decoration: none;
}

#w-app-header .mobile-nav button .fa, #w-app-header .mobile-nav button .far, #w-app-header .mobile-nav button .fas, #w-app-header .mobile-nav button .w-spinner {
    min-width: 2.4rem;
    opacity: .6;
}

.mobile-nav button {
    color: $weasel-key-value-list-key-color;
}

#w-app-header .mobile-nav li.active button {
    color: $burger-menu-active-tab-text-color;
    background: $burger-menu-active-tab-background;
}

#w-app-header .mobile-nav li.active button .fa, #w-app-header .mobile-nav li.active button .far, #w-app-header .mobile-nav li.active button .fas, #w-app-header .mobile-nav li.active button .w-spinner {
    opacity: 1;
}

.wrapper-error-message {
    position: relative;
    .error-message {
        width: fit-content;
        position: absolute;
        top: $margin-size;
    }
}

@media (max-width: 768px) {
    #w-app-header.mobile-nav-open {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 4;
        background: $burger-menu-background;
        margin: 0;
        padding: 0 20px 20px;
        align-items: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        overflow-x: hidden;
        overflow-y: auto;
    }
    #w-app-header.mobile-nav-open .mobile-nav {
        display: block;
    }
}
