.address-spinner {
    .rbt-aux {
        align-items: center;
        display: flex;
        bottom: 0;
        justify-content: center;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 34px;
    }
}

