@import "~/styles/_variables.scss";

.Change-Plan {
    &-available-title {
        margin-top: $margin-size * 5;
        margin-bottom: $margin-size;
    }
    &-full-description {
        font-weight: bold;
        margin: $margin-size 0 $margin-size * 5 0;
    }
    &-link-text {
        text-decoration: unset;
    }
    &-title {
        margin-bottom: $margin-size * 5;
    }
    &-spinner {
        display: flex;
        justify-content: center;
        padding-top: $padding-size * 2;
    }
}



