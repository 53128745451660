@import "~/styles/_variables.scss";

.Change-Plan-Dialog {
    &-grid-bottom {
        margin-bottom: $margin-size * 2;
        overflow: hidden;
    }
    &-papper {
        padding: $padding-size * 2;
        background-color: #f3f3f3;
        height: 100%;
        @include media-breakpoint-down(xs) {
            margin: $margin-size * 2 0;
        }
    }
    &-one-time-charge-block {
        display: flex;
        padding-bottom: 10px;
    }
    &-monthly-fee-block {
        display: flex;
        padding: 10px 0;
    }
    &-block-title {
        width: 30%;
    }
    &-total-block {
        display: flex;
        background-color: $text-hint-color;
        padding: 10px 8px;
        margin-top: 16px;
        margin-bottom: 12px;
        border-radius: 2px;
    }
    &-papper-left {
        margin: $margin-size * 2 $margin-size $margin-size 0;
    }
    &-papper-right {
        margin: $margin-size * 2 0 $margin-size $margin-size;
    }
    &-error-block {
        display: flex;
        border: 2px solid theme-color(danger);
        border-radius: 2px;
        padding: $padding-size * 1.5;
        margin-bottom: $margin-size * 3;
        background-color: theme-color-level(danger, -6);
        color: theme-color(danger);
        & > span {
            padding-left: 20px;
        }
    }
    &-button-right {
        text-align: right;
        @include media-breakpoint-down(xs) {
            text-align: center;
        }
    }
    &-button {
        @include media-breakpoint-down(xs) {
            width: 100%;
        }
    }
    &-dialog-content {
        padding-top: 0 !important;
        padding-bottom: $padding-size * 3 !important;
    }
}
