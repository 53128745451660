@import "./_variables.scss";

$ipos-content-max-width: 64rem !default;

.ipos-content-box {
    @extend .w-box;
    max-width: $ipos-content-max-width;
    margin-left: auto;
    margin-right: auto;

    &-header {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;

        &.ipos-bordered {
            border-bottom: 1px solid $weasel-border-color-solid;
            padding-bottom: .5rem;
            margin-bottom: 1rem;    
        }
    }
}

.ipos-flow-header {
    max-width: $ipos-content-max-width;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;

    &-buttons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
    }
}

.divider {
    margin: $margin-size * 2 0 !important;
    background-color: $border-color !important;
    height: 1px;
}

.label {
    font-size: 1rem;
    font-weight: 500;
}