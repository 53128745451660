@import "~/styles/_variables.scss";

.Change-Plan-Row-List {
    &-head {
        cursor: pointer;
        display: grid;
        grid-template-columns: 75% calc(25% - 20px) 20px;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $border-color-group;
        padding: 10px;
        margin-bottom: .5rem;
        position: relative;

        &-chevron {
            jutify-self: end;
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            left: -.25rem;
            top: -1px;
            bottom: -1px;
            width: .25rem;
            background: #2489d8;
        }

        &-selected {
            &:before {
                background: $success;
            }
        }
    }
    &-selected {
        color: $success;
    }

    &-nested {
        display: grid;
        grid-template-columns: 50% auto auto;
        align-items: center;
        margin: 0.5rem 0.5rem;
        padding: 8px;
        border: 1px solid $border-color-group;
        border-left-width: 4px;
        background-color: $background-color;

        &-selected {
            border: 1px solid $success;
            border-left-width: 4px;
        }
    }

    @media (max-width: 576px) {
        &-nested {
            grid-template-columns: repeat(auto-fill, 100%);
            grid-row-gap: 10px;
            margin: 0.5rem 0;

            button {
                width: 100%;
            }
        }
    }
    @media(max-width: 576px) {
        &-head {
            grid-template-columns: 50% auto auto;
        }
    }
}





