// Configure weasel-styles

$fa-font-path: '~weasel-styles/fonts';
$weasel-show-sidebar: false;
$weasel-window-background-base: #ec2a2a;
// $body-bg: #229fff;
// $body-color: #FFFFFF;
//rgba(30,43,60,0.805671)
$weasel-background-transparen: #fedf3d;


// Skip Open Sans loading, use Fira Sans instead

$weasel-disable-default-font-loading: true;
$font-family-sans-serif: 'Fira Sans', sans-serif;


// Apply KYIVSTAR colors

$primary: #229fff;
$brand-secondary: #dc3545;
$secondary: #fedf3d;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #343a40;
$success: #6fbc1c;