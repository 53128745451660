@import "~/styles/_variables.scss";

.Otp {
	width: inherit;
	margin-bottom: 10px;
	.progress {
		border-radius: 0;
		height: 0.5rem;
  	}
	&-tick {
		min-width: 100px;
	}
}
