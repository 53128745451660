@import "~/styles/_variables.scss";

.Dialog-actions {
    border: solid $border-color $border-size;
    background-color: $background-color;
    padding: $padding-size * 2;
    @include make-row();
    & > div {
        @include media-breakpoint-up(xs) {
            @include make-col(6);
        }
        &:last-child {
            text-align: right;
        }
    }
}