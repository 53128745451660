@import "~/styles/_variables.scss";

.title-search {
    padding-right: $padding-size;
}

.msisdns-contant {
    margin-top: $margin-size;
}

.acquisition-msisdn {
    &-msisdns {
        display: flex;
        flex-wrap: wrap;

        .custom-control {
            padding-right: $padding-size * 2;
            min-width: 141px;
        }
    }
}
