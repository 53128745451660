#w-login-body-custom {
    border-radius: $border-radius;
    padding: $weasel-body-padding-base;
    color:$white;
    background: $primary;
    box-shadow: $weasel-body-box-shadow;
    @media (max-width: $weasel-login-max-width) {
        border-radius: 0;
    }
}
    